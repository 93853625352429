import { PlatformControllerFlowAPI } from '@wix/yoshi-flow-editor';
import { PropKeysType, PropsI, bi } from '.';

export const biOnPropsChange = (
  flowAPI: PlatformControllerFlowAPI,
  prev: PropsI,
  cur: PropsI
) => {
  const changedProps = getChangedProps(prev, cur);
  for (const propKey of changedProps) {
    switch (propKey as PropKeysType) {
      case 'accountId':
        const connected = !prev.accountId && cur.accountId;
        const disconnected = prev.accountId && !cur.accountId;
        if (!connected && !disconnected) {
          console.log('Account changed but no bi -', { prev, cur });
        }
        const newStatus = connected ? 'connected' : 'disconnected';
        bi({ flowAPI }).statusChanged(newStatus);
        break;
      default:
        return;
    }
  }
};

function getChangedProps(prevProps, currentProps) {
  const changedProperties: PropKeysType[] = [];

  for (const key in prevProps) {
    if (prevProps.hasOwnProperty(key)) {
      if (prevProps[key] !== currentProps[key]) {
        changedProperties.push(key as PropKeysType);
      }
    }
  }

  return changedProperties as PropKeysType[];
}
