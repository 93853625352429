export const APP_DEF_ID = 'deb18ef1-e0c6-4668-a7bc-4a13785eb5b7';

export const FULL_WIDGET_ID = '9775dac1-735d-448f-9143-c9c70bd04de4'; // Gallery and header
export const HEADER_WIDGET_ID = '4aa5ffff-4e98-47ce-95fe-3b40a658ced4'; // Header
export const EXPAND_MODE_ID = '5e950125-a2e4-489f-bb0b-fb30677719e2'; // Expand Mode

export const FULL_WIDGET_CONTROLLER_ID =
  'deb18ef1-e0c6-4668-a7bc-4a13785eb5b7-x5kmw';

export const TPA_PAGE_ID = 'ig-feed-expand-popup';

export const HELP_ID = '985166aa-e528-474b-aecf-4b44017c377a';
