export enum ElementRoles {
  Widget = 'mainWidget',
  Header = 'header2',
  HeaderContainer = 'box1', // For global design on editor
  HeaderTitle = 'HeaderTitle',
  HeaderBtnContainer = 'box2',
  HeaderUserNameBtn = 'usernameBtn',
  HeaderHashtagBtn = 'hashtagsBtn',
  ProGallery = 'proGallery1',

  ExpandWidget = 'box1',
  ExpandPanel = 'box2',
  ExpandProGallery = 'proGallery2',
  ExpandPanelHeader = 'box3',
  ExpandIGBtn = 'button3',
  ExpandUserName = 'text2',
  ExpandCloseBtn = 'button4',
  LeftArrowBtn = 'button1',
  RightArrowBtn = 'button2',
  ExpandDescription = 'text1',
  ExpandDate = 'text3',
  PanelDivider = 'line1',

  EmptyStateWidget = 'emptyState1',
  EmptyStateTitle = 'text1',
  EmptyStateDescription = 'text2',
}
