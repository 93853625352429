import { ElementRoles } from '../../enums';
import {
  generateTemplate,
  convertMediasToPG,
  proGalleryEventListeners,
  selectorFactory,
  getStorage,
  LightboxData,
  PgItemType,
  createCarousel,
  getNextCursorAndHasNext,
  checkIfSameLastElement,
} from '../../utils/Viewer';
import { igApi } from '../../api';
import { bi } from '../../bi';

export const onExpandModeReady = async ($w, flowAPI, $widget) => {
  const { instance } = flowAPI.controllerConfig.appParams;
  const { isViewer, isPreview } = flowAPI.environment;
  const isViewMode = isViewer || isPreview;
  const { window } = flowAPI.controllerConfig.wixCodeApi;
  const { locale } = window;
  const { getAccount, getMedias } = igApi(instance);
  const { setExpandModeItem } = selectorFactory($w, flowAPI);

  if (!isViewMode) {
    await openExpandModeEditor();
  } else {
    await openExpandModeViewer();
  }

  async function openExpandModeViewer() {
    const lightboxContext: LightboxData = window.lightbox.getContext() || {};
    const {
      data: {
        data: { media: igData, user: userData, accountId, userMedias, paginationInstance },
      },
    } = lightboxContext;
    const demoUserName =
      userData.instagramAccount?.instagramInfo?.instagramUsername;
    const { closeModal } = lightboxContext;
    const { itemIndex } = lightboxContext.data.pgItem;
    const igMedias = igData;
    // Expand mode state variables
    let index = itemIndex;
    let isFetching;
    let selectedItem = igMedias[index];
    // Is demo or template
    const isDemo = !accountId;
    console.log('Viewer', igMedias.length, index);
    bi({ flowAPI }).instagramViewPost(selectedItem.type);


    // A11y
    $w(`#${ElementRoles.ExpandWidget}`).accessibility.tabIndex = -1;
    $w(`#${ElementRoles.ExpandCloseBtn}`).onClick(() => {
      // Prevent the expand from reopening onClose onEnter
      setTimeout(() => closeModal(window), 100);
    });

    await getMoreItems();

    setExpandModeItem(index, igMedias, locale, demoUserName, isDemo);

    $w(`#${ElementRoles.LeftArrowBtn}`).onClick(() => {
      setNewItem({ isPrev: true });
      setExpandModeItem(index, igMedias, locale, demoUserName, isDemo);
      bi({ flowAPI }).instagramViewPost(selectedItem.type, true);
    });

    $w(`#${ElementRoles.RightArrowBtn}`).onClick(async () => {
      setNewItem({ isPrev: false });
      setExpandModeItem(index, igMedias, locale, demoUserName, isDemo);
      await getMoreItems();
      bi({ flowAPI }).instagramViewPost(selectedItem.type, true);
    });

    async function getMoreItems() {
      const cursor = getNextCursorAndHasNext(userMedias);
      console.log('[getMoreItems Expand]', { cursor, index, leg: igMedias.length });
      if (!isFetching && index + 15 >= igMedias.length && cursor.hasNext) {
        isFetching = true;
        try {
          const medias = await getMedias(accountId, { paginationInstance });
          const convertedMedias = convertMediasToPG(medias);
          const isSameLast = checkIfSameLastElement(igMedias, convertedMedias);
          !isSameLast && medias && igMedias.push(...convertedMedias);
        } finally {
          isFetching = false;
        }
      }
    }

    // Not used until opening a link [thunderbolt]
    // proGalleryEventListeners(flowAPI, $w, paginationInstance);

    function setNewItem({ isPrev }: { isPrev: boolean }): void {
      const incrementIndex = (length: number, i: number) => (i + 1) % length;

      const decrementIndex = (length: number, i: number) =>
        (i - 1 + length) % length;

      const changeIndex = isPrev ? decrementIndex : incrementIndex;
      index = changeIndex(igMedias.length, index);
      selectedItem = igMedias[index];
    }
  }

  async function openExpandModeEditor() {
    // On editor showing only the first media as carousel
    const { accountId } = getStorage(flowAPI);
    console.log('Get Account Id', { accountId });
    let lastProGalleryPost: PgItemType;
    let userName: string | undefined;
    let isDemo = false;
    if (accountId) {
      const userData = await getAccount(accountId as string);
      userName = userData.instagramAccount?.instagramInfo?.instagramUsername;
      const posts = await getMedias(accountId as string);
      const latestPost = posts.media?.length ? posts.media[0] : {};
      lastProGalleryPost = convertMediasToPG({ media: [latestPost] })[0];
    } else {
      // Templates or demo
      isDemo = true;
      const { templateMedias, templateUser } = await generateTemplate(
        flowAPI,
        $widget.props,
      );
      userName =
        templateUser.instagramAccount?.instagramInfo?.instagramUsername;
      lastProGalleryPost = templateMedias.length
        ? templateMedias[0]
        : ({} as PgItemType);
    }
    const generatedAlbum = createCarousel(lastProGalleryPost);
    setExpandModeItem(0, [generatedAlbum], locale, userName, isDemo);
  }
};
