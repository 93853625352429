import { PlatformControllerFlowAPI } from '@wix/yoshi-flow-editor';
import { ElementRoles, PgCustomOnclick, PresetNames } from '../../enums';
import { selectorFactory } from './selectorFactory';
import { igApi } from '../../api';
import { FULL_WIDGET_CONTROLLER_ID } from '../../constants';
import { convertMediasToPG } from './convertMediasToPG';

type ListenerArgs = [
  pgWidgetType: any,
  flowAPI: PlatformControllerFlowAPI,
  openExpandMode: any,
  addedLightboxData: any,
];

export const proGalleryEventListeners = (
  flowAPI: PlatformControllerFlowAPI,
  $w,
  addedLightboxData?,
) => {
  const { name } = flowAPI.controllerConfig;
  // @ts-expect-error
  const { preset, accountId } = flowAPI.controllerConfig.config.props;
  const isMainWidget = FULL_WIDGET_CONTROLLER_ID === name;
  const proGalleryWidget = isMainWidget
    ? $w(`#${ElementRoles.ProGallery}`)
    : $w(`#${ElementRoles.ExpandProGallery}`);
  const { openExpandMode } = selectorFactory($w);

  const args = [
    proGalleryWidget,
    flowAPI,
    openExpandMode,
    addedLightboxData || [],
  ] as ListenerArgs;

  onItemClickedListener(...args);
  if (isMainWidget && preset !== PresetNames.Grid && accountId) {
    onGetMoreItemsListener(...args);
  }
};

const onItemClickedListener = (...args: ListenerArgs) => {
  const [pgWidget, flowAPI, openExpandMode, addedLightboxData] = args;
  // @ts-expect-error
  const { props } = flowAPI.controllerConfig.config;
  const { onClickAction } = props;
  pgWidget.onItemClicked((pgItem) => {
    switch (onClickAction) {
      case PgCustomOnclick.OpenExpand:
        openExpandMode({ pgItem, data: addedLightboxData });
        break;
      case PgCustomOnclick.OpenLink:
        pgWidget.manualStyleParams = {
          behaviourParams_item_clickAction: 'LINK',
        };
    }
  });
};

const onGetMoreItemsListener = (...args: ListenerArgs) => {
  const [pgWidget, flowAPI, _, addedLightboxData] = args;
  const { paginationInstance } = addedLightboxData;
  pgWidget.onGetMoreItems(async (e) => {
    // @ts-expect-error
    const { props } = flowAPI.controllerConfig.config;
    const { instance } = flowAPI.controllerConfig.appParams;
    const mediasApi = await igApi(instance).getMedias(props.accountId, {
      paginationInstance,
    });
    const pgMediasApi = convertMediasToPG(mediasApi);
    pgWidget.addItems(pgMediasApi);
    addedLightboxData.media = [...addedLightboxData.media, ...pgMediasApi];
    console.log('Added lightbox data', { addedLightboxData, pgWidget });
  });
};

const openLink = async (flowAPI) => {
  await flowAPI?.controllerConfig?.wixCodeApi?.location?.navigateTo({
    url: 'https://www.google.com/abc',
    target: '_blank',
    type: 'ExternalLink',
  });
};
