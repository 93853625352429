import {
  createBlocksModel,
  WidgetPropertyType,
} from '@wix/yoshi-flow-editor/blocks';
import { PgCustomOnclick, PresetNames } from '../../enums';
/*
  Place where blocks model is initialized.
  Blocks model allows you to define your Widget Public APIs in a single place.
  It will automatically provide `getExports` for Editor Script and provide typings based on the model for Viewer Script (via model.createController).
*/
export default createBlocksModel({
  widgetName: 'Gallery and Header',
  props: {
    // Here you can define your widget props
    onClickAction: {
      type: WidgetPropertyType.NUMBER,
      defaultValue: PgCustomOnclick.OpenExpand,
      description: 'Opens the lightbox with the expand mode widget',
    },
    accountId: {
      type: WidgetPropertyType.STRING,
      defaultValue: '',
      description: 'Contains the id of the ig account',
    },
    preset: {
      type: WidgetPropertyType.STRING,
      defaultValue: PresetNames.OneRow,
      description: 'Contains the current preset',
    },
    isClassic: {
      type: WidgetPropertyType.BOOLEAN,
      defaultValue: true,
      description: 'Contains the widget environment',
    },
    templatesData: {
      type: WidgetPropertyType.STRING,
      defaultValue: '',
      description:
        'Stringified object with folderName, userName and mediaCount',
    },
  },
  methods: {
    // Here you can define your widget public props
  },
  events: {
    // Here you can define events your widget will subscribe to
    widgetLoaded: {
      description: 'Fired when Widget loaded',
    },
  },
});
