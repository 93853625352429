import { onPageReady } from './onPageReady';
import model from './model';
import { biOnPropsChange } from '../../bi';
import { PresetNames } from '../../enums';

export default model.createController(
  ({ $bind, $widget, flowAPI, controllerConfig }) => {
    $widget.onPropsChanged(async (prev, cur) => {
      const didAccountChange = cur.accountId !== prev.accountId;
      const didTemplateDataChange = cur.templatesData !== prev.templatesData;
      const didEditorModeChange = cur.isClassic !== prev.isClassic;
      const isGrid =
        cur.preset === PresetNames.Grid || prev.preset === PresetNames.Grid;

      if (
        didAccountChange ||
        didTemplateDataChange ||
        didEditorModeChange ||
        isGrid
      ) {
        await onPageReady(controllerConfig.$w, flowAPI, $widget);
      }

      biOnPropsChange(flowAPI, prev, cur);
    });

    return {
      pageReady: async ($w) => {
        console.log('Page Ready');
        await onPageReady($w, flowAPI, $widget);
      },
      exports: {},
    };
  }
);
