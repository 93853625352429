import type { IWixWindow, ISiteApis, IWixAPI } from '@wix/yoshi-flow-editor';
import type {
  StructureLightbox,
  StructurePage,
} from '@wix/native-components-infra/dist/src/types/types';
import { TPA_PAGE_ID } from '../../constants';

export interface LightboxData {
  data?: any;
  closeModal: (window: IWixWindow, data?: any) => void;
}

export class LightboxService {
  private lightboxIds = new Map<string, string>();
  private window;
  constructor(private wixCodeApi: IWixAPI) {
    this.window = wixCodeApi.window;
  }

  openExpandMode = async (data?: any) => {
    const lightboxId = await this.getLightboxByTpaId(TPA_PAGE_ID);
    return this.window.openLightboxById(lightboxId, {
      data,
      closeModal: (window: IWixWindow, props?: any) => {
        this.closeExpandMode(window, props);
      },
    });
  };

  closeExpandMode = async (window: IWixWindow, data?: any) => {
    // @ts-expect-error
    return window.lightbox.close(data);
  };

  private getLightboxByTpaId = async (tpaPageId: string) => {
    if (this.lightboxIds.keys.length === 0) {
      await this.setLightboxIds(this.wixCodeApi.site);
    }
    const lightboxId = this.lightboxIds.get(tpaPageId);
    if (!lightboxId) {
      throw new Error(
        `lightbox ${tpaPageId} not found in ${Array.from(this.lightboxIds)}`,
      );
    }
    return lightboxId;
  };

  private setLightboxIds = async (site: ISiteApis) => {
    const siteStructure = await getSiteStructure(site);
    siteStructure.lightboxes.forEach((lightbox) => {
      this.lightboxIds.set(lightbox.tpaPageId, lightbox.id);
    });
  };
}

type StructurePageWithHiddenFields = StructurePage & {
  applicationId: string;
  tpaPageId: string;
};

type StructureLightboxWithHiddenFields = StructureLightbox & {
  id: string;
  appDefinitionId: string;
  tpaPageId: string;
};

type SiteStructureWithHiddenFields = {
  pages: StructurePageWithHiddenFields[];
  lightboxes: StructureLightboxWithHiddenFields[];
};

export const getSiteStructure = (
  site: ISiteApis,
): Promise<SiteStructureWithHiddenFields> => {
  return site.getSiteStructure({
    includePageId: true,
  }) as unknown as Promise<SiteStructureWithHiddenFields>;
};
