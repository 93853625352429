import { CursorPaging } from '@wix/ambassador-instagram-feed-v1-instagram-media/build/cjs/types.impl';
import { PaginationService } from './PaginationService';

export interface GetMediasOptions {
  limit?: number | null;
  isViewMode?: boolean;
  paginationInstance?: PaginationService | null;
}

export const setPagination = (
  paginationInstance: PaginationService,
  limit: number | null
): CursorPaging => {
  const cursorPaging: CursorPaging = {};
  limit && (cursorPaging.limit = limit);
  const cursor = paginationInstance.getCursor();
  cursor && (cursorPaging.cursor = cursor);

  return cursorPaging;
};

export const checkIfMediaBlocked = (
  paginationInstance: PaginationService
): string => {
  let isMediaError = '';
  if (!paginationInstance.getHasMoreMedias()) {
    isMediaError = 'Medias End';
  } else if (paginationInstance.getIsLoading()) {
    isMediaError = 'Still loading';
  }

  return isMediaError;
};
